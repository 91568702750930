<template>
	<div class="login-bg">
		<div class="login-box animate__animated animate__bounceIn">
			<div class="left animate__animated animate__bounceInLeft">
				<img class="bg" src="../../assets/img/bg.png" />
			</div>
			<div class="right">
				<h1 class="animate__animated animate__bounceInDown">智慧椰林管理系统</h1>
				<div class="login-li animate__animated animate__bounceInLeft">
					<img src="../../assets/img/user.png" />
					<el-input v-model="form.uname" :autofocus="true" @keyup.enter="submitForm" type="text" clearable
						placeholder="请输入用户名" />
				</div>
				<div class="login-li animate__animated animate__bounceInLeft">
					<img src="../../assets/img/pw.png" />
					<el-input v-model="form.pwd" @keyup.enter="submitForm" show-password type="password" clearable
						placeholder="请输入密码" />
				</div>
				<div class="login-li animate__animated animate__bounceInUp">
					<el-button color="#00ad79" class="login-btn el-white" @click="submitForm" type="primary">
						立即登录</el-button>
				</div>
				<div class="login-li icp animate__animated animate__bounceInDown">
					忘记密码?请联系管理员！
				</div>
			</div>
		</div>
		<!-- icp -->
		<div class="footer animate__animated animate__bounceInUp">
			琼ICP备19001016号-3
		</div>
	</div>
</template>


<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/login";
	export default {
		name: 'login',
		data() {
			return {
				form: {
					uname: '',
					pwd: '',
				},
				btnList: []
			}
		},
		methods: {
			// 获取菜单路由
			getmenu() {
				this.$http.post(api.menu)
					.then((res) => {
						// 提交路由
						this.$store.dispatch('MENULIST', res.data)
					})
			},
			// 全部按钮
			allbtn() {
				this.$http.post(api.allbutton)
					.then((res) => {
						this.btnList = []
						let list = res.data
						list.forEach(item => {
							if (item.typename) {
								this.btnList.push(item.typename)
							}
						})
						// 提交路由
						this.$store.dispatch('BTNLIST', this.btnList)
					})
			},
			// 登录
			submitForm() {
				if (this.form.uname == '') {
					ElMessage({
						message: '请输入用户名',
						type: 'warning',
					})
				} else if (this.form.pwd == '') {
					ElMessage({
						message: '请输入密码',
						type: 'warning',
					})
				} else {
					var that = this
					this.$http.post(api.login, this.form)
						.then((res) => {
							// 存token
							sessionStorage.setItem('token', res.data.token)
							// 用户id
							sessionStorage.setItem('userid', res.data.id)
							// 村id
							this.$store.dispatch('VILLAGEID', Number(res.data.village_id))

							this.getmenu()
							this.allbtn()
							setTimeout(function() {
								that.$router.replace('/')
							}, 200)
						}).catch(function(err) {
							console.log(err)
						})
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.login-bg ::v-deep .el-input__inner {
		padding-left: 35px;
	}

	.login-bg {
		width: 100%;
		display: flex;
		overflow: hidden;
		align-items: center;
		justify-content: center;
		height: 100vh;
		background: url(../../assets/img/login_bg.png);
		background-size: 100% 100%;

		.right {
			flex: 1;
			overflow: hidden;

			h1 {
				font-size: 30px;
				text-align: center;
				margin-top: 10px;
				margin-bottom: 30px;
			}

			.icp {
				text-align: center;
			}

			.login-li {
				position: relative;
				margin-bottom: 20px;

				.login-btn {
					margin-top: 20px;
					width: 100%;
				}

				img {
					position: absolute;
					left: 10px;
					top: 10px;
					cursor: pointer;
					height: 20px;
					width: 20px;
					z-index: 9;
				}
			}
		}

		.left {
			flex: 1;
			padding-right: 60px;
			display: flex;
			text-align: center;
			align-items: center;
			justify-content: center;

			.bg {
				display: block;
				width: 100%;
			}
		}

		.login-box {
			max-width: 800px;
			display: flex;
			overflow: hidden;
			background-color: #fff;
			border-radius: 10px;
			padding: 50px 60px;
		}
	}

	.footer {
		width: 100%;
		position: fixed;
		bottom: 0;
		left: 0;
		border-top: 0.5px solid #ffffff;
		color: #ffffff;
		line-height: 40px;
		text-align: center;
	}
</style>
