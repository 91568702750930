import request from './request'

const api = {
	// 登录
	login: '/auth/login',
	// 左侧菜单列表
	menu: '/menu/leftMenu',
	// 所有的按钮操作
	allbutton: '/menu/allbutton',
	// 验证码
	captcha: '/auth/captcha',
}

export {
	request,
	api
}
